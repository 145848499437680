import axios, { AxiosResponse } from "axios";
import { Kategorier, Klartexter } from "../../interfaces/Klartexter";

export const getKlartext = async (): Promise<AxiosResponse<Klartexter>> => {
  const uri = `/api/rapportera/referensdata/klartext`;
  return await axios.get(encodeURI(uri));
};

export const getKategorier = async (): Promise<AxiosResponse<Kategorier>> => {
  const uri = `/api/rapportera/referensdata/betalningstidkategorier`;
  return await axios.get(encodeURI(uri));
};
