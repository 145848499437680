import { BetalningstiderDel, betalningstiderDelFromBetalningstidDelStrings } from "./BetalningstiderDel";
import { BetalningstiderDelStrings } from "./BetalningstiderDelStrings";

export interface Betalningstider {
  S0till9: BetalningstiderDel | null;
  S10till49: BetalningstiderDel | null;
  S50till249: BetalningstiderDel | null;

  omvandFactoringS0till9: BetalningstiderDel | null;
  omvandFactoringS10till49: BetalningstiderDel | null;
  omvandFactoringS50till249: BetalningstiderDel | null;
}

export const betalningstiderDelStringsMapToBetalningstider = (delar: { [key: string]: BetalningstiderDelStrings }): Betalningstider => {
  const convert = betalningstiderDelFromBetalningstidDelStrings;
  return {
    S0till9: convert(delar["S0_9"]),
    S10till49: convert(delar["S10_49"]),
    S50till249: convert(delar["S50_249"]),
    omvandFactoringS0till9: convert(delar["OMVAND_FACTORING_S0_9"]),
    omvandFactoringS10till49: convert(delar["OMVAND_FACTORING_S10_49"]),
    omvandFactoringS50till249: convert(delar["OMVAND_FACTORING_S50_249"]),
  } as Betalningstider;
}
