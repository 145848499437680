import axios, { AxiosResponse } from "axios";
import { BetalningstidResponse, HamtaAllaForetagResponse, HamtaAllaPerioderResponse, HamtaSammanlagdaBetalningstiderResponse } from "@/interfaces/Statistik";

export interface RequestBody {
	periodFrom: Date
}

export interface HamtaBetalningstidRequest {
	identitetsbeteckning: string,
	periodFrom: Date
}

export const hamtaAllaPerioder = async () : Promise<AxiosResponse<HamtaAllaPerioderResponse>> => {
	const uri = '/api/statistik/hamtaAllaPerioder';
	return await axios.get<HamtaAllaPerioderResponse>(encodeURI(uri));
}

export const hamtaAllaForetag = async () : Promise<AxiosResponse<HamtaAllaForetagResponse>> => {
	const uri = '/api/statistik/hamtaAllaForetag';
	return await axios.get<HamtaAllaForetagResponse>(encodeURI(uri));
}

export const hamtaSammanlagdaBetalningstider = async (periodFrom: Date) : Promise<AxiosResponse<HamtaSammanlagdaBetalningstiderResponse>> => {
	const requestBody = { periodFrom } as RequestBody;
	const uri = `/api/statistik/hamtaSammanlagdaBetalningstider`;
	return await axios.post<HamtaSammanlagdaBetalningstiderResponse>(encodeURI(uri), requestBody);
}

export const hamtaBetalningstider = async (periodFrom: Date, identitetsbeteckning: string) : Promise<AxiosResponse<BetalningstidResponse>> => {
	const requestBody = { identitetsbeteckning, periodFrom } as HamtaBetalningstidRequest;
	const uri = '/api/statistik/hamtaBetalningstid';
	return await axios.post<BetalningstidResponse>(encodeURI(uri), requestBody);
}