import { BetalningstiderDelStrings } from "./BetalningstiderDelStrings";

export interface BetalningstiderDel {
  avtaladBetalningstidGenomsnitt: number;
  faktiskBetalningstidGenomsnitt: number;
  andelBetaladeFakturorAvtaladBetalningstid: number;
}

export const betalningstiderDelFromBetalningstidDelStrings = (
  betaltidDel: BetalningstiderDelStrings
): BetalningstiderDel | null => {
  if (!betaltidDel.nagotAttRapportera) {
    return null;
  }

  const del = {
    avtaladBetalningstidGenomsnitt: parseInt(
      betaltidDel.avtaladBetalningstidGenomsnitt
    ),
    faktiskBetalningstidGenomsnitt: parseInt(
      betaltidDel.faktiskBetalningstidGenomsnitt
    ),
    andelBetaladeFakturorAvtaladBetalningstid: parseInt(
      betaltidDel.andelBetaladeFakturorAvtaladBetalningstid
    ),
  } as BetalningstiderDel;

  if (isNaN(del.avtaladBetalningstidGenomsnitt)
    || isNaN(del.faktiskBetalningstidGenomsnitt)
    || isNaN(del.andelBetaladeFakturorAvtaladBetalningstid)) {
    return null;
  }

  return del;
};

export const betalningstiderDelStringsFromBetalningstiderDel = (
  betaltidDel: BetalningstiderDel | null
): BetalningstiderDelStrings => {
  if (betaltidDel == null) {
    return {
      avtaladBetalningstidGenomsnitt: "",
      faktiskBetalningstidGenomsnitt: "",
      andelBetaladeFakturorAvtaladBetalningstid: "",
      nagotAttRapportera: false,
    } as BetalningstiderDelStrings;
  }

  return {
    avtaladBetalningstidGenomsnitt: "" + betaltidDel.avtaladBetalningstidGenomsnitt,
    faktiskBetalningstidGenomsnitt: "" + betaltidDel.faktiskBetalningstidGenomsnitt,
    andelBetaladeFakturorAvtaladBetalningstid: "" + betaltidDel.andelBetaladeFakturorAvtaladBetalningstid,
    nagotAttRapportera: true,
  } as BetalningstiderDelStrings;
};
