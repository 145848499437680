import VueRouter from 'vue-router'
import { Store } from 'vuex'
import { Authenticator } from '@bv/oidc-auth-client'
import { State } from '../store'

export function authFilter(options: {
  router: VueRouter.Router,
  auth: Authenticator,
  store: Store<State>,
  callback?: string,
}): void {
  const { router, auth, store, callback = 'login' } = options
  router.beforeEach(async (to, _, next) => {
    // Login callback after redirect from IdP
    if (to.path === callback && Object.keys(to.query).length) {
      store.commit('startLoading')
      try {
        const target = await auth.authorizationResponse(<never>to.query)
        if (typeof target === 'string') {
          return next({ path: target, replace: true })
        } else if (typeof target === 'object') {
          return next({ ...target, replace: true })
        } else {
          return next({ name: 'Test', replace: true })
        }
      } catch (error) {
        return next({ name: 'Test', replace: true })
      } finally {
        store.commit('stopLoading')
      }
    }

    const authRequired = to.matched.some(route => route.meta?.auth);

    if (!auth.authenticated && (!auth.checked || authRequired)) {
      // store.commit('startLoading')
      try {
        const { authenticated, authorizationUri } = await auth.sessionStatus({ refresh: authRequired })
        if (!authenticated && authRequired) {
          // Never returns
          const state = redirectState(to);
          await auth.authorize({ authorizationUri, state: state })
        }
      } catch (e) {
        if (authRequired) {
          throw e
        }
      } finally {
        // store.commit('stopLoading')
      }
    }

    next()
  })
}

// Create a minimal Location object or a string path if there are no parameters
export function redirectState(route: VueRouter.RouteLocation): string | VueRouter.RouteLocation {
  const { path, hash, query } = route
  const result = {} as VueRouter.RouteLocation;
  if (query && Object.keys(query).length) {
    result.query = query
  }
  if (hash && hash !== '#') {
    result.hash = hash
  }
  if (Object.keys(result).length) {
    result.path = path
    return result
  }
  return path
}

