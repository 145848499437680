/* eslint-disable prettier/prettier */
import { AxiosResponse } from "axios";
import { store } from "../../store";
import { HamtaAllaForetagResponse, HamtaAllaPerioderResponse, BetalningstidResponse, HamtaSammanlagdaBetalningstiderResponse, SammanlagdaBetalningstider, ForetagBetalningstider, ForetagInfo } from "@/interfaces/Statistik";
import { hamtaAllaForetag, hamtaAllaPerioder, hamtaSammanlagdaBetalningstider, hamtaBetalningstider } from "./BetalningstiderApi";
import { Period } from "@/interfaces/Period";

export const hamtaOchSparaAllaPerioder = (): void => {
	store.commit("setVantarPaHamtaAllaPerioderResponse", true);
	hamtaAllaPerioder()
	.then((response: AxiosResponse<HamtaAllaPerioderResponse>) => {
		const allaPerioderResponse: HamtaAllaPerioderResponse = response.data;
		allaPerioderResponse.perioder.sort((a: Period, b: Period) => {
			const aDate = new Date(a.periodFrom);
			const bDate = new Date(b.periodFrom);
			return bDate.getTime() - aDate.getTime();
		});
		
		store.commit("setAllaPerioder", allaPerioderResponse);
	})
	.catch((error: any) => {
		console.log(error.message);
	})
	.finally(() => {
		store.commit("setVantarPaHamtaAllaPerioderResponse", false);
	});
}

export const hamtaOchSparaAllaForetag = (): void => {
	store.commit("setVantarPaHamtaAllaForetagResponse", true);
	hamtaAllaForetag()
	.then((response: AxiosResponse<HamtaAllaForetagResponse>) => {
		const allaForetagResponse: HamtaAllaForetagResponse = response.data;
		store.commit("hamtaAllaForetagOk", allaForetagResponse);
	})
	.catch((error: any) => {
		console.log(error.message);
		store.commit("hamtaAllaForetagFel");
	})
	.finally( () => {
		store.commit("setVantarPaHamtaAllaForetagResponse", false);
	});
}

export const hamtaOchSparaSammanlagdaBetalningstider = (period: Period): void => {
	store.commit("setVantarPaHamtaSammanlagdaBetalningstider", true);

  	hamtaSammanlagdaBetalningstider(period.periodFrom)
 	.then((response: AxiosResponse<HamtaSammanlagdaBetalningstiderResponse>) => {
		const sammanlagdaBetalningstiderResponse: HamtaSammanlagdaBetalningstiderResponse = response.data;
		const sammanlagdaBetalningstider = {
			period: period,
			antalForetag: sammanlagdaBetalningstiderResponse.antalForetag ?? 0,
			rapportRader: sammanlagdaBetalningstiderResponse.rapportRader
		} as SammanlagdaBetalningstider;
		store.commit("setAktuelltSammanlagdaBetalningstider", sammanlagdaBetalningstider);
  	})
	.catch((error: any) => {
		console.log(error.message);
		store.commit("setAktuelltSammanlagdaBetalningstider", null);
	})
	.finally( () => {
		store.commit("setVantarPaHamtaSammanlagdaBetalningstider", false);
	});
}

export const hamtaOchSparaForetagBetalingstider = (period: Period, foretagInfo: ForetagInfo) => {
	store.commit("setAktuelltForetagBetalningstider", null); // Återställ tidigare företag
	store.commit("setVantarPaHamtaBetalningstid", true);
	hamtaBetalningstider(period.periodFrom, foretagInfo.identitetsbeteckning)
	.then((response: AxiosResponse<BetalningstidResponse>) => {
		const betalningstidResponse: BetalningstidResponse = response.data;
		const foretagBetalningstider = {
		period: period,
		foretagInfo: foretagInfo,
		rapportRader: betalningstidResponse.rapportRader
		} as ForetagBetalningstider;

		// Sortera listan
		foretagBetalningstider.foretagInfo.rapportPerioder.sort((a: Period, b: Period) => {
			const aDate = new Date(a.periodFrom);
			const bDate = new Date(b.periodFrom);
			return bDate.getTime() - aDate.getTime();
		});

		store.commit("setAktuelltForetagBetalningstider", foretagBetalningstider);
	})
	.catch((error: any) => {
		console.log(error.message);
	})
	.finally(() => {
		store.commit("setVantarPaHamtaBetalningstid", false);
	});
}