import axios, { AxiosResponse } from "axios";

export interface RequestBody {
  periodFrom: Date
}

export const getForetagSmeApi = async (periodFrom: Date): Promise<AxiosResponse<ArrayBuffer>> => {
  const uri = `/api/rapportera/foretag-sme-for-artal`;
  const requestBody: RequestBody = { periodFrom }; 
  return await axios.post(encodeURI(uri), requestBody, { responseType: 'arraybuffer'});
}
