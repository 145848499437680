export default class Exception {
  message: string | any;
  status: ErrorTypes;

  constructor(message: string | any, status: "WARNING" | "ERROR" | "INFO") {
    this.message = message;
    this.status = ErrorTypes[status];
  }
}

enum ErrorTypes {
  WARNING = "WARNING",
  ERROR = "ERROR",
  INFO = "INFO",
}
