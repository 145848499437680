import type { BetalningstiderFull } from "@/interfaces/BetalningstiderFull";

import axios from "axios";

export interface SkickaHandlingarForUnderskriftRequest {
  registreraBetalningstider: BetalningstiderFull
}

export const skickaHandlingarForUnderskrift = async (
  request: SkickaHandlingarForUnderskriftRequest
): Promise<void> => {
  const uri = `/api/rapportera/skickaHandlingarForUnderskrift`;
  return await axios.post(encodeURI(uri), request);
};