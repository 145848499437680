export const fmtOrgnr = (orgnr: string) => {
  const sliceAt = 6;
  return orgnr.slice(0, sliceAt) + "-" + orgnr.slice(sliceAt);
};

export const formatPeriods = (periods: string[]) => {
  return periods.map(period => {
    return formatPeriod(period);
  });
};

export const formatPeriod = (period: string) => {
  const [start, end] = period.split("-");
  return start + "-07-01–" + end + "-06-30";
};

export const formatId = (id: string) => {
  return [id.slice(0, -4), "-", id.slice(-4)].join('');
};

export const formatDatum = (datum: string) => {
  return [datum.slice(0, 10), " kl. ", datum.slice(11, 16)].join('');
};