import { BetalningstiderDelStrings } from "./BetalningstiderDelStrings";
import { betalningstiderDelFromBetalningstidDelStrings } from "./BetalningstiderDel";
import { Kontaktuppgifter } from "./Kontaktuppgifter";
import { KontaktuppgifterOchBetalningstider } from "./KontaktuppgifterOchBetalningstider";
import type { Betalningstider } from "./Betalningstider";
import type { Foretag } from "./Foretag";
import { Forsakran } from "./Forsakran";
import { Period } from "./Period";

export interface BetalningstiderFull {
  foretag: Foretag;
  kontaktuppgifterOchBetalningstider: KontaktuppgifterOchBetalningstider;
  forsakran: Forsakran;
  periodFrom: Date;
}

export const betalningstiderFullFromDelar = (
  delar: {
    [key: string]: BetalningstiderDelStrings;
  },
  foretag: Foretag,
  kontaktuppgifter: Kontaktuppgifter,
  forsakran: Forsakran,
  hasOmvandFactoring: boolean,
  period: Period
): BetalningstiderFull => {
  const convert = betalningstiderDelFromBetalningstidDelStrings;
  if (!hasOmvandFactoring) {
    return {
      foretag: foretag,
      kontaktuppgifterOchBetalningstider: {
        kontaktuppgifter: kontaktuppgifter,
        betalningstider: {
          S0till9: convert(delar["S0_9"]),
          S10till49: convert(delar["S10_49"]),
          S50till249: convert(delar["S50_249"]),
          omvandFactoringS0till9: null,
          omvandFactoringS10till49: null,
          omvandFactoringS50till249: null,
        } as Betalningstider,
      } as KontaktuppgifterOchBetalningstider,
      forsakran: forsakran,
      periodFrom: period.periodFrom
    } as BetalningstiderFull;
  }
  return {
    foretag: foretag,
    kontaktuppgifterOchBetalningstider: {
      kontaktuppgifter: kontaktuppgifter,
      betalningstider: {
        S0till9: convert(delar["S0_9"]),
        S10till49: convert(delar["S10_49"]),
        S50till249: convert(delar["S50_249"]),
        omvandFactoringS0till9: convert(delar["OMVAND_FACTORING_S0_9"]),
        omvandFactoringS10till49: convert(delar["OMVAND_FACTORING_S10_49"]),
        omvandFactoringS50till249: convert(delar["OMVAND_FACTORING_S50_249"]),
      } as Betalningstider,
    } as KontaktuppgifterOchBetalningstider,
    forsakran: forsakran,
    periodFrom: period.periodFrom
  } as BetalningstiderFull;
};
