import ApiException from "@/exceptions/ApiException";
import NotFoundException from "@/exceptions/NotFoundException";
import type { Foretag } from "@/interfaces/Foretag";
import axios from "axios";
import type { Rapporteringsbehorigheter } from "@/interfaces/Rapporteringsbehorigheter";

export interface PeriodFrom {
  periodFrom: Date
}

export interface RequestBody {
  identitetsbeteckning: string,
  periodFrom: Date
};

export interface RapporteringsbehorigheterRequest {
  identitetsbeteckning: string,
  personnummer: string,
  periodFrom: Date
};

const checkRapporteringsskyldighet = async (
  identitetsbeteckning: string, periodFrom: Date
): Promise<string | null> => {
  const uri = `/api/rapportera/rapporteringsskyldigheter`;
  const requestBody: RequestBody = { identitetsbeteckning, periodFrom };

  return await axios
    .post(encodeURI(uri), requestBody)
    .then(function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    })
    .catch(function (error) {
      if (error.response.status == 404) {
        throw new NotFoundException("Ingen träff", "ERROR");
      }
      throw new ApiException();
    });
};

const getForetag = async (
  identitetsbeteckning: string, periodFrom: Date
): Promise<Foretag | null> => {
  const uri = `/api/rapportera/foretag`;
  const requestBody: RequestBody = { identitetsbeteckning, periodFrom };

  return await axios
    .post(encodeURI(uri), requestBody)
    .then(function (response) {
      if (response.status === 200) {
        return convertToForetag(response.data);
      }
      return null;
    })
    .catch(function (error) {
      if (error.response.status == 404) {
        throw new NotFoundException("Ingen träff", "INFO");
      }
      throw new ApiException();
    });
};

const rapporteringsbehorigheter = async (
  identitetsbeteckning: string,
  personnummer: string
): Promise<Rapporteringsbehorigheter | null> => {
  const uri = `/api/rapportera/rapporteringsbehorigheter/${identitetsbeteckning}/personnummer/${personnummer}`;
  return await axios
    .get(encodeURI(uri))
    .then(function (response) {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    })
    .catch(function (error) {
      if (error.response.status == 404) {
        return null;
      }
      throw new ApiException();
    });
};

const convertToForetag = (response: any): Foretag => {
  const foretag: Foretag = {
    identitetsbeteckning: response.identitetsbeteckning,
    namn: response.namn,
    storleksklassKod: response.storleksklassKod,
    storleksklassKlartext: response.storleksklassKlartext,
  };

  return foretag;
};

export { checkRapporteringsskyldighet };
export { getForetag };
export { rapporteringsbehorigheter };
