import type { Foretag } from "@/interfaces/Foretag";
import { decodeBase64, UserClaims } from "@bv/oidc-auth-client";
import { InjectionKey } from "vue";
import { useStore as baseUseStore, createStore, Store } from "vuex";
import { Forsakranstext } from "@/interfaces/Forsakranstexter";
import { KontaktuppgifterOchBetalningstider } from "@/interfaces/KontaktuppgifterOchBetalningstider";
import { Rapporteringsbehorigheter } from "@/interfaces/Rapporteringsbehorigheter";
import { 
  StatistikBetalningstider, 
  ForetagBetalningstider, 
  HamtaAllaForetagResponse, 
  HamtaAllaPerioderResponse, 
  SammanlagdaBetalningstider 
} from "@/interfaces/Statistik";
import { Period } from "@/interfaces/Period";
import VuexPersister from 'vuex-persister'

const vuexPersister = new VuexPersister({
  storage: window.sessionStorage
})

export const key = Symbol() as InjectionKey<Store<State>>;

export interface State {
  foretag: Foretag;
  rapportperiod: Period;
  kontaktuppgifterOchBetalningstider: KontaktuppgifterOchBetalningstider | null;
  rapporteringsskyldighet: string;
  harIntygat: boolean;
  rapporteringsbehorighet: Rapporteringsbehorigheter | null;
  loading: boolean;
  error: Record<string, any> | null

  accessToken: string | null;
  idToken: string | null;
  refreshToken: string | null;
  scope: string;
  endpoint: string;

  // Ofullständig, men ska egentligen vara något i stil med
  //user: UserClaims | null;
  user: any | null;
  idp: string;
  requestUserinfo: boolean;
  klartexter: { [key: string]: string };
  klartexterLoaded: boolean;
  forsakranstexter: Forsakranstext[];
  allaPerioder: Period[],
  vantarPaHamtaAllaPerioderResponse: boolean,

  statistik: StatistikBetalningstider,
}

const extractClaims = (token?: string | null, index = 1): Record<string, any> | null => {
  if (!token) {
    return null
  }

  let part: string
  if (token.includes('.')) {
    const parts = token.split('.')
    part = parts[index]
  } else if (index === 1) {
    part = token
  } else {
    return null
  }

  return JSON.parse(decodeBase64(part))
}


const mutations = {
  sparaKontaktuppgifterOchBetalningstider(state: State, kontaktuppgifterOchBetalningstider: KontaktuppgifterOchBetalningstider | null): void {
    state.kontaktuppgifterOchBetalningstider = kontaktuppgifterOchBetalningstider;
  },
  glomKontaktuppgifterOchBetalningstider(state: State): void {
    state.kontaktuppgifterOchBetalningstider = null;
  },
  sparaForetag(state: State, foretag: Foretag): void {
    state.foretag = foretag;
  },
  sparaForetagsNummer(state: State, foretagsNummer: string): void {
    state.foretag.identitetsbeteckning = foretagsNummer;
  },
  sparaRapporteringsskyldighet(state: State, rapporteringsskyldighet: string): void {
    state.rapporteringsskyldighet = rapporteringsskyldighet;
    state.harIntygat = false;
  },
  sparaRapporteringsbehorighet(state: State, rapporteringsbehorighet: Rapporteringsbehorigheter | null): void {
    state.rapporteringsbehorighet = rapporteringsbehorighet;
  },
  startLoading(state: State) {
    state.loading = true;
  },

  stopLoading(state: State) {
    state.loading = false;
  },

  error(state: State, args: { error: any }) {
    const { error } = args;
    if ('toJSON' in error) {
      state.error = error.toJSON();
    } else if (typeof error === 'object') {
      try {
        state.error = JSON.parse(JSON.stringify(error));
      } catch (e) {
        state.error = { message: '' + error };
      }
    } else {
      state.error = { message: '' + error };
    }
    state.loading = false;
  },

  authenticated(state: State, args: { user: UserClaims }) {
    state.user = args.user;
    state.error = null;
    state.loading = false;
  },

  tokens(state: State, args: Pick<State, 'idToken' | 'accessToken' | 'refreshToken'>) {
    const { idToken, accessToken, refreshToken } = args;
    if (idToken !== undefined) {
      state.idToken = idToken;
    }

    if (accessToken !== undefined) {
      state.accessToken = accessToken;
    }

    if (refreshToken !== undefined) {
      state.refreshToken = refreshToken;
    }
  },

  idp(state: State, idp: string) {
    state.idp = idp;
  },

  scope(state: State, scope: string) {
    state.scope = scope;
  },

  user(state: State, user: any) {
    state.user = user;
  },

  endpoint(state: State, endpoint: string) {
    state.endpoint = endpoint;
  },

  requestUserinfo(state: State, value: boolean) {
    state.requestUserinfo = value;
    sessionStorage.setItem('requestUserinfo', value ? 'true' : 'false');
  },

  forget(state: State) {
    // auth
    state.user = null;
    state.error = null;
    state.loading = false;
    state.idToken = null;
    state.accessToken = null;
    state.refreshToken = null;

    // orgnr
    state.foretag.identitetsbeteckning = "";
    state.foretag.namn = "";

    // tidigare försök till inrapportering
    state.kontaktuppgifterOchBetalningstider = null;
  },

  emptyForetag(state: State) {
    state.foretag.identitetsbeteckning = "";
    state.foretag.namn = "";
  },

  klartexter(state: State, klartexter: { [key: string]: string }): void {
    state.klartexter = klartexter;
    state.klartexterLoaded = true;
  },

  forsakranstexter(state: State, forsakranstexter: Forsakranstext[]) {
    state.forsakranstexter = forsakranstexter;
  },

  /* Betalningstider statistik */
  hamtaAllaForetagOk(state: State, allaForetagResponse: HamtaAllaForetagResponse): void {
    state.statistik.felHamtaAllaForetag = false;
    state.statistik.allaForetag = allaForetagResponse.foretag ?? [];
  },

  hamtaAllaForetagFel(state: State): void {
    state.statistik.felHamtaAllaForetag = true;
    state.statistik.allaForetag = [];
  },

  setAllaPerioder(state: State, allaPerioderResponse: HamtaAllaPerioderResponse) {
    state.allaPerioder = allaPerioderResponse.perioder ?? [];
  },

  setVantarPaHamtaAllaPerioderResponse(state: State, flagga: boolean) {
    state.vantarPaHamtaAllaPerioderResponse = flagga;
  },

  setAktuelltForetagBetalningstider(state: State, foretagBetalningstider: ForetagBetalningstider): void {
    state.statistik.aktuelltForetagBetalningstider = foretagBetalningstider;
  },

  setAktuelltSammanlagdaBetalningstider(state: State, sammanlagdaBetalningstider: SammanlagdaBetalningstider): void {
    state.statistik.sammanlagdaBetalningstider = sammanlagdaBetalningstider;
  },

  setVantarPaHamtaBetalningstid(state: State, flagga: boolean): void {
    state.statistik.vantarPaHamtaBetalningstidResponse = flagga;
  },

  setVantarPaHamtaSammanlagdaBetalningstider(state: State, flagga: boolean): void {
    state.statistik.vantarPaHamtaSammanlagdaBetalningstiderResponse = flagga;
  },

  setVantarPaHamtaAllaForetagResponse(state: State, flagga: boolean) {
    state.statistik.vantarPaHamtaAllaForetagResponse = flagga;
  },

  setRapportperiod(state: State, period: Period) {
    state.rapportperiod = period;
  },

  anvandareHarIntygat(state: State): void {
    state.harIntygat = true;
  },

  aterstallHarIntygat(state: State) {
    state.harIntygat = false;
  }
};

export const store = createStore<State>({
  state: {
    backend_url: "",
    foretag: {
      identitetsbeteckning: "",
      storleksklassKod: "",
      storleksklassKlartext: "",
      namn: "",
    } as Foretag,
    rapportperiod: {} as Period, 
    kontaktuppgifterOchBetalningstider: null,
    rapporteringsskyldighet: "NEJ",
    harIntygat: false,
    rapporteringsbehorighet: null,
    loading: false,
    error: null,

    accessToken: null,
    idToken: null,
    refreshToken: null,
    //scope: 'openid https://scopes.oidc.se/1.0/naturalPersonName',
    scope: 'openid email profile https://scopes.oidc.se/1.0/naturalPersonPnr https://scopes.oidc.se/1.0/authnInfo',
    endpoint: process.env.VUE_APP_AUTH_API + '/session',
    user: {},
    idp: "",
    requestUserinfo: sessionStorage.getItem('requestUserinfo') === 'true',
    klartexter: {},
    klartexterLoaded: false,
    forsakranstexter: [] as Forsakranstext[],
    allaPerioder: [],
    vantarPaHamtaAllaPerioderResponse: false,
    hamtaAllaPerioderOk: false,

    statistik: {
      felHamtaAllaForetag: false,
      allaForetag: [],
      sammanlagdaBetalningstider: null,
      aktuelltForetagBetalningstider: null,
      vantarPaHamtaBetalningstidResponse: false,
      vantarPaHamtaSammanlagdaBetalningstiderResponse: false,
      vantarPaHamtaAllaForetagResponse: false
    } as StatistikBetalningstider,
  } as State,
  mutations: mutations,
  plugins: [vuexPersister.persist],

  getters: {
    name: (state) => state.user?.name || '',
    authenticated: (state) => state.user != null,
    idTokenClaims: (state) => extractClaims(state.idToken),
    accessTokenClaims: (state) => extractClaims(state.accessToken)
  },

});

export const useStore = (): Store<State> => {
  return baseUseStore(key);
};

export default store;
