export const serUtSomIdentitetsbeteckning = (vad: string): boolean => {
  return /(\d|-)+$/.test(vad);
};

// godkänner inte bindestreck, dessa saneras separat
export const validIdentitetsbeteckning = (
  identitetsbeteckning: string
): boolean => {
  const regex = /(^[0-9]{12}$)|(^[0-9]{10}$)/g;
  return regex.test(identitetsbeteckning);
};

export const validAntalDagar = (antalDagar: string): boolean => {
  const regex = /^((0)|([1-9][0-9]*))$/g;
  return regex.test(antalDagar) && parseInt(antalDagar) < 1000;
};

export const validProcent = (procent: string): boolean => {
  const regex = /^((0)|([1-9][0-9]{0,1}))$/g;
  return regex.test(procent) || procent == "100";
};

export const validNamn = (namn: string): boolean => {
  const regex = /^.{1,100}$/g;
  return regex.test(namn);
};

export const validTelefonnummer = (tfn: string): boolean => {
  const regex = /^\+?[0-9\- ]+$/g;
  return regex.test(tfn);
};

export const validEpostadress = (epost: string): boolean => {
  // https://stackoverflow.com/questions/48055431/can-it-cause-harm-to-validate-email-addresses-with-a-regex
  const parts = epost.trim().split("@");
  if (parts.length != 2) {
    return false;
  }

  const startsWithDot = (str: string): boolean => str.indexOf(".") == 0;
  const endsWithDot = (str: string): boolean =>
    str.lastIndexOf(".") == str.length - 1;
  const hasDoubleDot = (str: string): boolean => str.indexOf("..") != -1;
  const hasDot = (str: string): boolean => str.indexOf(".") != -1;

  const local = parts[0];
  const domain = parts[1];

  return (
    // local:
    // får inte vara tom
    local.length > 0 &&
    // börjar ej med punkt
    !startsWithDot(local) &&
    // slutar ej med punkt
    !endsWithDot(local) &&
    // har ej upprepade punkter
    !hasDoubleDot(local) &&
    // domän:
    // ska minst innehålla a.b
    domain.length >= 3 &&
    // börjar ej med punkt
    !startsWithDot(domain) &&
    // slutar ej med punkt
    !endsWithDot(domain) &&
    // innehåller minst en punkt
    hasDot(domain) &&
    // har ej upprepade punkter
    !hasDoubleDot(domain)
  );
};
