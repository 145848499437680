const EN_DASH = "\u2013";

export interface Period {
	periodFrom: Date,
	periodTom: Date
}

export const periodTillText = (period: Period) => {
    return `${period.periodFrom}${EN_DASH}${period.periodTom}`;
}
