import { createRouter, createWebHistory } from "vue-router";
import Hem from "@/views/rapportera/Hem.vue";
import ForetagSok from "@/views/rapportera/ForetagSok.vue";
import Inloggning from "@/views/rapportera/Inloggning.vue";
import Rapportera from "@/views/rapportera/Rapportera.vue";
import Kvittens from "../views/rapportera/Kvittens.vue";
import StatistikStartsida from "@/views/statistik/StatistikStartsida.vue";
import StatistikSok from "@/views/statistik/StatistikSok.vue";
import StatistikForetag from "@/views/statistik/StatistikForetag.vue";
import StatistikStorlekskategori from "@/views/statistik/StatistikStorlekskategori.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Hem",
      component: Hem,
      meta: { auth: false, title: "Startsida - Rapportera betalningstider" },
    },
    {
      path: "/inloggning",
      name: "Inloggning",
      component: Inloggning,
      meta: { auth: true },
    },
    {
      path: "/inloggning/:target",
      name: "InloggningRedirect",
      component: Inloggning,
      meta: { auth: true },
    },
    {
      path: "/rapportera",
      name: "Rapportera",
      component: Rapportera,
      meta: { auth: true, title: "Rapportera - Rapportera betalningstider" },
    },
    {
      path: "/foretag",
      name: "Foretag",
      component: ForetagSok,
      meta: { auth: true, title: "Hämta storlekskategori - Rapportera betalningstider" },
    },
    {
      path: "/kvittens",
      name: "Kvittens",
      component: Kvittens,
      meta: { auth: false, title: "Kvittens - Rapportera betalningstider" },
    },

    {
      path: "/intyg",
      name: "Intyg",
      component: Rapportera,
      meta: { auth: true, title: "Intyg - Rapportera betalningstider" },
    },

    // Betalningstider statistik
    {
      path: "/statistik",
      name: "StatistikStartsida",
      component: StatistikStartsida,
      meta: { auth: false, title: "Startsida - Betalningstider statistik" },
    },
    {
      path: "/statistik/sok",
      name: "StatistikSok",
      component: StatistikSok,
      meta: { auth: false, title: "Sök företag - Betalningstider statistik" },
    },
    {
      path: "/statistik/foretag",
      name: "StatistikForetag",
      component: StatistikForetag,
      meta: { auth: false, title: "Info företag - Betalningstider statistik" },
    },
    {
      path: "/statistik/storlekskategori",
      name: "StatistikStorlekskategori",
      component: StatistikStorlekskategori,
      meta: { auth: false, title: "Info storlekskategori - Betalningstider statistik" },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: "Hem", params: {} }
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to !== from) {
    const nyTitel = to.meta.title as string;
    if (nyTitel) {
      const gammalTitel: string = document.title;
      const idx: number = gammalTitel.indexOf("]");
      if (idx != -1) {
        document.title = `${gammalTitel.substring(0, idx + 1)} ${nyTitel}`;
      } else {
        document.title = nyTitel;
      }
    }
  }
  next();
});

export default router;
