import axios from "axios";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import i18n from "./i18n";
import { authFilter } from "@/auth";
import { Authenticator } from '@bv/oidc-auth-client';
import AxiosSupport from '@bv/oidc-auth-client-axios';

import "./../public/style.scss";

const callback = '/inloggning';
const auth = new Authenticator({
  baseUrl: process.env.VUE_APP_AUTH_API || '/oauth2',
  redirectUri: window.location.origin + callback,
  userinfo: true,

  // Global callback vid fel i samband med följande operationer:
  // - sessionStatus
  // - authorize
  // - authorizationResult
  // - logout
  authError({ error }) {
    store.commit('error', { error });
  },

  sessionEstablished({ user, idp, verified, state }) {
    if (idp) {
      store.commit('idp', idp);
    }
    store.commit('scope', auth.scopes.join(' '));
    store.commit('authenticated', { user });
    store.commit('tokens', {
      idToken: auth.idToken,
      accessToken: auth.token,
      refreshToken: (auth as any)._rt
    });

    store.commit("startLoading");
    auth.userinfo()
    .then((resp: any) => {
      store.commit("user", resp);
    })
    .finally(() => {
      store.commit("stopLoading");
    });
  },

  sessionTerminated() {
    store.commit('forget');
  }
})

// Authenticator.start måste anropas för att installera event handlers,
// request interceptors och ladda state.
auth.start();
AxiosSupport.installInterceptor({ axios, auth });
authFilter({ router, auth, store, callback: callback });

const app = createApp(App);
app.config.globalProperties.$auth = auth;
app.provide("$axios", axios)
  .use(store, key)
  .use(router)
  .use(i18n)
  .mount("#app");
