import { DefineDateTimeFormat, createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import sv from "@/locales/sv.json";

const setDateTimeFormats = {
  short: {
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
} as DefineDateTimeFormat;

const datetimeFormats = {
  sv: setDateTimeFormats,
  en: setDateTimeFormats,
};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "sv",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  warnHtmlInMessage: "off",
  messages: {
    sv,
    en,
  },
  datetimeFormats,
});
