<template>
  <div id="laddar">
    <div class="load-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="scss">

/* Källa: loading.io/css */
.load-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.load-ellipsis div {
  color: inherit;
  background-color: $color-darkblue-bv;
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.load-ellipsis div:nth-child(1) {
  left: 8px;
  animation: load-ellipsis1 0.6s infinite;
}
.load-ellipsis div:nth-child(2) {
  left: 8px;
  animation: load-ellipsis2 0.6s infinite;
}
.load-ellipsis div:nth-child(3) {
  left: 32px;
  animation: load-ellipsis2 0.6s infinite;
}
.load-ellipsis div:nth-child(4) {
  left: 56px;
  animation: load-ellipsis3 0.6s infinite;
}
@keyframes load-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes load-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes load-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* Slut källa */
</style>
