import Exception from "./Exception";

export default class ApiException extends Exception {
  constructor(message?: string, status?: any) {
    super("general.errors.technical_error_try_again", "ERROR");

    if (message) {
      this.message = message;
    }
    if (status) {
      this.status = status;
    }
  }
}
