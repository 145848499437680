import { Store } from "vuex";
import { State } from "../store";
import { Authenticator } from "@bv/oidc-auth-client";

export const inloggad = (store: Store<State>): boolean => {
  return store.state.user != null && 
    store.state.user.name != null;
};

export const loggaUt = async (props: Record<string,any> | undefined) => {
  const fullPath = window.location.origin;
  await props?.$auth.logout(fullPath);
};

export const loggaIn = async (props: Record<string, any> | undefined, store: Store<State>) => {
  if(props == undefined) {
    return;
  }
  const auth = props?.$auth as Authenticator;
  await auth.authorize({ idp: store.state.idp, scope: store.state.scope, state: 'login' })
};
