import axios from "axios";

export const hamtaAktuellMiljo = async (): Promise<string> => {
	const uri = `/api/aktuellMiljo`;
	try {
		const response = await axios.get(encodeURI(uri));
		const data = await response.data;
		return data;
	} catch (error: any) {
		throw error.message;
	}
};